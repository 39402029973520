import "../avatar";
import { LitElement, html, css } from "lit";
import { customElement, property, state, query } from "lit/decorators.js";
import {
    BillingMode,
    Company,
    CompanyLifeCycleEvent,
    CompanyStatus,
    companyStatusColor,
    companyStatusLabel,
    Employee,
    EmployeeStatus,
    employeeStatusColor,
    employeeStatusLabel,
    Feature,
    Role,
} from "@pentacode/core/src/model";
import {
    UpdateCompanyParams,
    UpdateBillingParams,
    GetCompanyParams,
    UpdateEmployeeParams,
    ExportCompanyParams,
    ImportCoreDataResponse,
} from "@pentacode/core/src/api";
import { formatDate, toDateString } from "@pentacode/core/src/util";
import { app, router } from "../../init";
import { config, shared } from "../../styles";
import { singleton } from "../../lib/singleton";
import { alert, confirm } from "../alert-dialog";
import { BillingDialog } from "../billing-dialog";
import "../spinner";
import { Scroller } from "../scroller";
import { Checkbox } from "../checkbox";
import { DateInput } from "../date-input";
import { Textarea } from "../textarea";
import "../popover";
import { readFileAsText, saveFile, selectFile } from "../../lib/util";
import { serialize } from "@pentacode/core/src/encoding";
import { live } from "lit/directives/live.js";
import { popover } from "../../directives/popover";
import { DateString } from "@pentacode/openapi/src/units";
import { RestoreTimeEntriesDialog } from "./restore-time-entries-dialog";
import { UnlockRevenueEntriesDialog } from "./unlock-revenue-entries-dialog";
import { HOGAST_UNIONS, HogastUnionId } from "@pentacode/core/src/hogast";
import { sortLifeCycleEvents } from "@pentacode/core/src/lifecycle-events";

@customElement("ptc-admin-company")
export class AdminCompany extends LitElement {
    @property({ type: Number })
    companyId: number | null = null;

    @state()
    private _company: Company | null = null;

    @state()
    private _loading = false;

    @state()
    private _displayAllEmployees = false;

    @state()
    private _importResult: ImportCoreDataResponse | null = null;

    @singleton("ptc-billing-dialog", true)
    private _billingDialog: BillingDialog;

    @singleton("ptc-restore-time-entries-dialog")
    private _restoreTimeEntriesDialog: RestoreTimeEntriesDialog;

    @singleton("ptc-unlock-revenue-entries-dialog")
    private _unlockRevenueEntriesDialog: UnlockRevenueEntriesDialog;

    @query(".new-event-form")
    private _newEventForm: HTMLFormElement;

    @query("#timeLineScroller")
    private _timeLineScroller: Scroller;

    private get _companyInfo() {
        const form = this.renderRoot!.querySelector("#companyForm") as HTMLFormElement;
        const data = form && new FormData(form);
        return (
            (data && {
                id: parseInt(data.get("id") as string),
                name: data.get("name") as string,
                address: data.get("address") as string,
                postalCode: data.get("postalCode") as string,
                city: data.get("city") as string,
                phone: data.get("phone") as string,
                email: data.get("email") as string,
                features: data.getAll("features") as Feature[],
                comments: data.get("comments") as string,
                startLedgers: data.get("startLedgers") as string,
            }) ||
            null
        );
    }

    private get _billingInfo() {
        const form = this.renderRoot!.querySelector("#billingForm") as HTMLFormElement;
        const data = form && new FormData(form);
        return (
            (data && {
                billingMode: data.get("billingMode") as BillingMode,
                hogastUnionId: (data.get("hogastUnionId") as HogastUnionId) || null,
                hogastMemberId: (data.get("hogastMemberId") as string) || null,
                name: data.get("name") as string,
                address: data.get("address") as string,
                addressLine1: data.get("addressLine1") as string,
                addressLine2: data.get("addressLine2") as string,
                postalCode: data.get("postalCode") as string,
                city: data.get("city") as string,
                phone: data.get("phone") as string,
                email: data.get("email") as string,
                vatId: data.get("vatId") as string,
                comments: data.get("comments") as string,
            }) ||
            null
        );
    }

    private get _companyInfoChanged() {
        const before = this._company;
        const after = this._companyInfo;

        return (
            before &&
            after &&
            before.id === after.id &&
            (before.name !== after.name ||
                before.address !== after.address ||
                before.postalCode !== after.postalCode ||
                before.city !== after.city ||
                before.phone !== after.phone ||
                before.email !== after.email ||
                before.comments !== after.comments ||
                before.features.length !== after.features.length ||
                after.features.some((f) => !before.features.includes(f)))
        );
    }

    private get _billingInfoChanged() {
        const before = this._company?.billing?.info;
        const after = this._billingInfo;
        return (
            after &&
            (!before ||
                before.billingMode !== after.billingMode ||
                before.hogastUnionId !== after.hogastUnionId ||
                before.hogastMemberId !== after.hogastMemberId ||
                before.name !== after.name ||
                before.addressLine1 !== after.addressLine1 ||
                before.addressLine2 !== after.addressLine2 ||
                before.postalCode !== after.postalCode ||
                before.city !== after.city ||
                before.phone !== after.phone ||
                before.email !== after.email ||
                before.comments !== after.comments)
        );
    }

    private get _newEventData(): Partial<CompanyLifeCycleEvent> {
        const form = this._newEventForm;
        const data = form && new FormData(form);
        return data
            ? {
                  comment: data.get("comment") as string,
                  status: (data.get("status") as CompanyStatus) || undefined,
                  date: (data.get("date") as DateString) || toDateString(new Date()),
              }
            : { comment: "" };
    }

    async updated(changes: Map<string, unknown>) {
        if (changes.has("companyId")) {
            this._importResult = null;
            this._displayAllEmployees = false;
            await this._load();
            await this.updateComplete;

            if (this._timeLineScroller) {
                this._timeLineScroller.scrollTop = this._timeLineScroller.scrollHeight;
            }
        } else if (changes.has("_company")) {
            this._resetForms();
        }
    }

    private async _resetForms() {
        await this.updateComplete;
        this._resetCompanyForm();
        this._resetBillingForm();
        setTimeout(() => this.requestUpdate(), 100);
    }

    private _resetCompanyForm() {
        (["name", "address", "postalCode", "city", "phone", "email", "comments", "country"] as const).forEach(
            (prop) => {
                const input = this.renderRoot.querySelector(`#companyForm [name="${prop}"]`) as HTMLInputElement;
                input.value = this._company?.[prop] || "";
            }
        );
        for (const input of this.renderRoot.querySelectorAll(
            `#companyForm [name="features"]`
        ) as NodeListOf<Checkbox>) {
            input.checked = !!this._company?.features.includes(input.value as Feature);
        }
    }

    private _resetBillingForm() {
        (
            [
                "name",
                "addressLine1",
                "addressLine2",
                "postalCode",
                "city",
                "phone",
                "email",
                "comments",
                "vatId",
                "billingMode",
                "hogastUnionId",
                "hogastMemberId",
            ] as const
        ).forEach((prop) => {
            const input = this.renderRoot.querySelector(`#billingForm [name="${prop}"]`) as HTMLInputElement;
            input.value = this._company?.billing?.info[prop] || "";
        });
    }

    private async _load() {
        if (!this.companyId) {
            this._company = null;
            return;
        }
        this._loading = true;
        try {
            this._company = await app.api.getCompany(
                new GetCompanyParams({ id: this.companyId, forceReload: true, includeEvents: true })
            );
        } catch (e) {
            alert(e.message, { type: "warning" });
        }
        this._loading = false;
    }

    private async _submitUpdateCompanyInfo(e: FocusEvent) {
        e.preventDefault();

        if (!this._companyInfo || !this._company) {
            return;
        }

        this._loading = true;
        try {
            await app.api.updateCompany(new UpdateCompanyParams(this._companyInfo));
            await this._load();
            this.dispatchEvent(new CustomEvent("updated"));
        } catch (e) {
            await alert(e.message, { type: "warning" });
        }
        this._loading = false;
    }

    private async _submitUpdateBillingInfo(e: FocusEvent) {
        e.preventDefault();

        if (!this._billingInfo || !this._company) {
            return;
        }

        this._loading = true;
        try {
            const {
                name,
                email,
                addressLine1,
                addressLine2,
                city,
                postalCode,
                phone,
                vatId,
                comments,
                billingMode,
                hogastMemberId,
                hogastUnionId,
            } = this._billingInfo;
            await app.api.updateCompany(
                new UpdateCompanyParams({
                    id: this._company.id,
                    billing: new UpdateBillingParams({
                        name,
                        email,
                        addressLine1,
                        addressLine2,
                        city,
                        postalCode,
                        phone,
                        vatId,
                        comments,
                        billingMode,
                        hogastMemberId,
                        hogastUnionId,
                    }),
                })
            );
            this.dispatchEvent(new CustomEvent("updated"));
        } catch (e) {
            await alert(e.message, { type: "warning" });
        }
        this._loading = false;
    }

    private async _addPaymentMethod(company: Company) {
        const complete = await this._billingDialog.show(company);

        if (complete) {
            this.dispatchEvent(new CustomEvent("updated"));
            alert("Zahungsmittel erfolgreich hinzugefügt!");
        }
    }

    private _openStripeDashboard(company: Company) {
        if (!company.billing) {
            return;
        }

        window.open(
            `https://dashboard.stripe.com${
                company.billing.customer && company.billing.customer.livemode ? "" : "/test"
            }/customers/${company.billing.customerId}`,
            "_blank"
        );
    }

    private async _submitNewEvent(e: Event) {
        e.preventDefault();

        this._loading = true;
        try {
            await app.api.updateCompany(
                new UpdateCompanyParams({
                    id: this._company!.id,
                    newLifeCycleEvent: new CompanyLifeCycleEvent(this._newEventData),
                })
            );
            await this._load();
            this.dispatchEvent(new CustomEvent("updated"));
            this._clearNewEventForm();
        } catch (e) {
            alert(e.message, { type: "warning" });
        }
        this._loading = false;
    }

    private _clearNewEventForm() {
        this.renderRoot
            .querySelectorAll(
                `.new-event-form [name="comment"], .new-event-form [name="status"], .new-event-form [name="date"]`
            )
            .forEach((input: HTMLInputElement | HTMLSelectElement) => (input.value = ""));
    }

    private async _deleteEvent(event: CompanyLifeCycleEvent) {
        if (
            !(await confirm("Willst du diesen Eintrag wirklich löschen?", "Löschen", "Abbrechen", {
                title: "Eintrag Löschen",
                type: "destructive",
            }))
        ) {
            return;
        }

        this._loading = true;
        try {
            await app.api.updateCompany(
                new UpdateCompanyParams({
                    id: this._company!.id,
                    deleteLifeCycleEvent: event.id,
                })
            );
            await this._load();
            this.dispatchEvent(new CustomEvent("updated"));
        } catch (e) {
            alert(e.message, { type: "warning" });
        }
        this._loading = false;
    }

    private async _unlockRevenueEntries() {
        if (!this._company) {
            return;
        }

        await this._unlockRevenueEntriesDialog.show({
            companyId: this._company.id,
        });
    }

    private async _restoreDeletedTimeEntries() {
        if (!this._company) {
            return;
        }

        await this._restoreTimeEntriesDialog.show({
            companyId: this._company.id,
            employees: [...this._company.employees],
        });
    }

    private async _deleteCompany() {
        if (!this._company) {
            return;
        }

        if (
            !(await confirm(
                `Sind Sie sicher dass Sie das Unternehmen ${this._company.name} (ID: ${this._company.id}) löschen möchten?`,
                "Löschen",
                "Abbrechen",
                { type: "destructive" }
            ))
        ) {
            return;
        }

        this._loading = true;

        try {
            await app.api.deleteCompany(this._company.id);
            router.go("", {});
            this.companyId = this._company = null;
            this.dispatchEvent(new CustomEvent("updated"));
            await this._load();
        } catch (e) {
            await alert(e.message, { type: "warning" });
        }

        this._loading = false;
    }

    private async _setContactPerson(employee: Employee, isContactPerson: boolean) {
        if (!this._company) {
            return;
        }

        this._loading = true;

        try {
            await app.api.updateEmployee(
                new UpdateEmployeeParams({
                    id: employee.id,
                    isContactPerson,
                })
            );
            await this._load();
        } catch (e) {
            await alert(e.message, { type: "warning" });
        }

        this._loading = false;
    }

    private async _export() {
        this._loading = true;
        try {
            const exp = await app.api.exportCompany(new ExportCompanyParams({ companyId: this._company!.id }));
            saveFile(
                `Export_${this._company!.name}_${new Date().toISOString()}.json`,
                "application/json",
                JSON.stringify(serialize(exp))
            );
        } catch (e) {
            await alert(e.message, { type: "warning" });
        }
        this._loading = false;
    }

    private async _import() {
        try {
            const file = await selectFile();
            this._importResult = null;
            if (file) {
                this._loading = true;
                this._importResult = await app.api.importCoreData({
                    fileContent: await readFileAsText(file),
                    companyId: this.companyId!,
                });
                await this._load();
                await this.updateComplete;
            }
        } catch (e) {
            await alert(e.message, { type: "warning" });
        }

        this._loading = false;
    }

    static styles = [
        config,
        shared,
        Checkbox.styles,
        DateInput.styles,
        Textarea.styles,
        css`
            :host {
                position: relative;
            }

            input[readonly] {
                border: none;
            }

            .payment-method {
                padding: 0.5em 0.5em 0.5em 0.5em;
                border: solid 1px var(--shade-2);
                margin-bottom: 1em;
                border-radius: var(--border-radius);
                position: relative;
            }

            .payment-method .pill {
                position: absolute;
                top: 0.5em;
                right: 0.5em;
            }

            .payment-method-detail {
                display: flex;
                align-items: center;
                margin: 0.5em 0;
            }

            .payment-method-detail-label {
                font-weight: bold;
                width: 8em;
                text-align: right;
                margin-right: 1em;
            }

            .new-event-form textarea {
                padding-bottom: 3em;
            }

            .lifecycle-event:not(:hover) .delete-event-button {
                opacity: 0;
            }

            .lifecycle-event.future:not(:hover) {
                opacity: 0.5;
            }
        `,
    ];

    private _renderCompanyForm(company: Company) {
        return html`
            <form id="companyForm" @input=${() => this.requestUpdate()} @submit=${this._submitUpdateCompanyInfo}>
                <div class="spacing grid">
                    <div class="field">
                        <label>ID</label>
                        <input readonly name="id" .value=${live(company.id.toString())} />
                    </div>

                    <div class="field">
                        <label>Erstellungsdatum</label>
                        <input readonly .value=${live(formatDate(company.created))} />
                    </div>

                    <div class="field">
                        <label>Status</label>
                        <div
                            class="padded box"
                            style="--color-highlight: ${companyStatusColor(company.effectiveStatus)}"
                        >
                            ${companyStatusLabel(company.effectiveStatus)}
                        </div>
                    </div>
                </div>

                <div class="field">
                    <label>Unternehmensname</label>
                    <input name="name" placeholder="Unternehmensname" />
                </div>

                <div class="field">
                    <label>Adresse</label>
                    <input type="text" name="address" placeholder="Strasse / Hausnummer" />
                </div>

                <div class="spacing grid">
                    <div class="field">
                        <label>Postleitzahl</label>
                        <input type="number" pattern="d*" name="postalCode" placeholder="PLZ" />
                    </div>

                    <div class="field">
                        <label>Stadt</label>
                        <input type="text" name="city" placeholder="Stadt" />
                    </div>

                    <div class="field">
                        <label>Land</label>
                        <select name="country" disabled>
                            <option value="DE">Deutschland</option>
                            <option value="AT">Österreich</option>
                        </select>
                    </div>
                </div>

                <div class="spacing grid">
                    <div class="field">
                        <label>Telefon</label>
                        <input type="text" name="phone" placeholder="Telefon" />
                    </div>

                    <div class="field">
                        <label>Emailadresse</label>
                        <input type="email" name="email" placeholder="Email" />
                    </div>
                </div>

                <div class="field">
                    <label>Bemerkungen</label>
                    <textarea name="comments"></textarea>
                </div>

                <div class="spacing grid">
                    <div class="field">
                        <label>Features</label>
                        <div class="small spacing grid">
                            <ptc-checkbox-button
                                name="features"
                                .value=${Feature.Employees}
                                label="Mitarbeiter"
                                buttonClass="slim ghost"
                                disabled
                            ></ptc-checkbox-button>
                            <ptc-checkbox-button
                                name="features"
                                .value=${Feature.Roster}
                                label="Dienstplan"
                                buttonClass="slim ghost"
                                disabled
                            ></ptc-checkbox-button>
                            <ptc-checkbox-button
                                name="features"
                                .value=${Feature.Time}
                                label="Stempeluhr"
                                buttonClass="slim ghost"
                            ></ptc-checkbox-button>
                            <ptc-checkbox-button
                                name="features"
                                .value=${Feature.Revenues}
                                label="Umsätze"
                                buttonClass="slim ghost"
                            ></ptc-checkbox-button>
                            <ptc-checkbox-button
                                name="features"
                                .value=${Feature.Planning}
                                label="Planung"
                                buttonClass="slim ghost"
                            ></ptc-checkbox-button>
                            <ptc-checkbox-button
                                name="features"
                                .value=${Feature.StaffApp}
                                label="Mitarbeiter-App"
                                buttonClass="slim ghost"
                            ></ptc-checkbox-button>
                        </div>
                    </div>
                </div>

                <div class="horizontal spacing evenly stretching layout" ?hidden=${!this._companyInfoChanged}>
                    <button class="primary">Speichern</button>
                    <button
                        class="transparent"
                        type="button"
                        @click=${() => {
                            this._resetCompanyForm();
                            this.requestUpdate();
                        }}
                    >
                        Abbrechen
                    </button>
                </div>
            </form>
        `;
    }

    private _renderBillingForm() {
        return html`
            <form id="billingForm" @input=${() => this.requestUpdate()} @submit=${this._submitUpdateBillingInfo}>
                <div class="double-padded border-top">
                    <div class="margined uppercase">Abrechnung</div>

                    <div class="fields horizontal evenly stretching spacing layout">
                        <div class="field">
                            <label>Abrechnungsmodus</label>
                            <select name="billingMode">
                                <option .value=${BillingMode.Default}>Standard</option>
                                <option .value=${BillingMode.Hogast}>Hogast</option>
                                <option .value=${BillingMode.Legacy}>Alt</option>
                            </select>
                        </div>
                        <div class="field" ?hidden=${this._billingInfo?.billingMode !== BillingMode.Hogast}>
                            <label>Hogast Dachverband</label>
                            <select
                                name="hogastUnionId"
                                ?required=${this._billingInfo?.billingMode === BillingMode.Hogast}
                            >
                                ${HOGAST_UNIONS.map(
                                    (union) => html` <option .value=${union.id}>${union.name}</option> `
                                )}
                            </select>
                        </div>
                        <div class="field" ?hidden=${this._billingInfo?.billingMode !== BillingMode.Hogast}>
                            <label>Hogast Mitgliedsnummer</label>
                            <input
                                name="hogastMemberId"
                                ?required=${this._billingInfo?.billingMode === BillingMode.Hogast}
                            />
                        </div>
                    </div>
                </div>

                <div class="double-padded border-top">
                    <div class="margined uppercase">Rechnungsanschrift</div>

                    <div class="field">
                        <label>Name</label>
                        <input name="name" placeholder="Name" />
                    </div>

                    <div class="field">
                        <label>Adresse</label>
                        <div class="spacing vertical layout">
                            <input type="text" name="addressLine1" placeholder="Strasse / Hausnummer (1. Zeile)" />
                            <input type="text" name="addressLine2" placeholder="Strasse / Hausnummer (2. Zeile)" />
                        </div>
                    </div>

                    <div class="spacing grid">
                        <div class="field">
                            <label>Postleitzahl</label>
                            <input type="number" pattern="d*" name="postalCode" placeholder="PLZ" />
                        </div>

                        <div class="field">
                            <label>Stadt</label>
                            <input type="text" name="city" placeholder="Stadt" />
                        </div>
                    </div>

                    <div class="spacing grid">
                        <div class="field stretch">
                            <label>Telefon</label>
                            <input type="text" name="phone" placeholder="Telefon" />
                        </div>

                        <div class="field stretch">
                            <label>Emailadresse</label>
                            <input type="email" name="email" placeholder="Email" />
                        </div>
                    </div>

                    <div class="field">
                        <label>USt-ID</label>
                        <input type="text" name="vatId" />
                    </div>

                    <div class="field">
                        <label>Kommentare</label>
                        <ptc-textarea name="comments"></ptc-textarea>
                    </div>
                </div>

                <div
                    class="double-margined horizontal spacing evenly stretching layout"
                    ?hidden=${!this._billingInfoChanged}
                >
                    <button class="primary">Speichern</button>
                    <button
                        class="transparent"
                        type="button"
                        @click=${() => {
                            this._resetBillingForm();
                            this.requestUpdate();
                        }}
                    >
                        Abbrechen
                    </button>
                </div>
            </form>
        `;
    }

    private _renderLifeCycleEvents() {
        if (!this._company) {
            return;
        }

        const events = sortLifeCycleEvents(this._company.lifeCycleEvents);
        let status: CompanyStatus | undefined;

        return html`
            ${events.map((event) => {
                const prevStatus = status;
                if (event.status) {
                    status = event.status;
                }
                return html`
                    <div
                        class="lifecycle-event blue ${event.date > toDateString(new Date()) ? "future" : ""}"
                        style="border-left: dashed 1px var(--shade-3); margin: 0 1em; --color-highlight: ${companyStatusColor(
                            status
                        )}"
                    >
                        <div
                            class="horizontal spacing center-aligning layout"
                            style="margin-left: -0.5em; padding-top: 1em;"
                        >
                            <div
                                style="width: 1em; height: 1em; border-radius: 100%; background: var(--color-highlight);"
                            ></div>
                            <div class="small stretch bold">${formatDate(event.date)}</div>
                            <button
                                class="tiny red skinny transparent delete-event-button"
                                @click=${() => this._deleteEvent(event)}
                            >
                                <i class="trash"></i>
                            </button>
                            <div class="small subtle">${event.createdBy}</div>
                        </div>
                        <div class="padded horizontally-margined">${event.comment}</div>
                        ${prevStatus && prevStatus !== status
                            ? html`
                                  <div
                                      class="tiny horizontally-padded horizontally-margined horizontal spacing center-aligning layout"
                                  >
                                      <div class="pill" style="--color-highlight: ${companyStatusColor(prevStatus)}">
                                          ${companyStatusLabel(prevStatus)}
                                      </div>
                                      <i class="arrow-right"></i>
                                      <div class="pill" style="--color-highlight: ${companyStatusColor(status)}">
                                          ${companyStatusLabel(status)}
                                      </div>
                                  </div>
                              `
                            : ""}
                    </div>
                `;
            })}
        `;
    }

    render() {
        const company = this._company;

        if (!company) {
            return html`
                <div class="fullbleed centering vertical subtle layout">
                    <i class="building giant"></i>
                    <div>Kein Unternehmen gewählt.</div>
                </div>
            `;
        }

        const activeEmployees = company.employees.filter((e) => e.active);

        const statusOrder = [EmployeeStatus.Active, EmployeeStatus.Probation, EmployeeStatus.Retired];

        const employees = company.employees
            .filter((e) => e.role < Role.Worker || e.isContactPerson || (this._displayAllEmployees && e.active))
            .sort(
                (a, b) =>
                    a.role - b.role ||
                    Number(b.isContactPerson) - Number(a.isContactPerson) ||
                    statusOrder.indexOf(a.status) - statusOrder.indexOf(b.status)
            );

        return html`
            <div class="fullbleed horizontal layout">
                <div class="vertical layout stretch collapse">
                    <div class="padded center-aligning spacing horizontal layout">
                        <div class="stretch"></div>
                        <button class="small slim ghost" @click=${this._export}>
                            <i class="file-export"></i>
                            Exportieren
                        </button>
                        <a
                            class="small slim ghost button"
                            href="https://dashboard.stripe.com${company.billing?.customer?.livemode
                                ? ""
                                : "/test"}/customers/${company.billing?.customerId}"
                            ?disabled=${!company.billing}
                            target=${"Stripe" as any}
                        >
                            <i class="sack-dollar"></i>
                            In Stripe Öffnen
                        </a>
                        <a
                            class="small slim ghost button"
                            href="${process.env.PTC_MANAGE_URL}/login?admin=1&company=${company.id}"
                            target=${"Pentacode" as any}
                        >
                            <i class="arrow-right"></i>
                            In Pentacode Öffnen
                        </a>
                    </div>

                    <ptc-scroller class="stretch collapse">
                        <div class="padded" style="max-width: 50em; margin: 0 auto;">
                            <div class="double-padded border-top">
                                <div class="margined uppercase">Support Tools <i class="magic-wand-sparkles"></i></div>

                                <div class="horizontal layout stretch">
                                    <div class="horizontally-margined">
                                        <button class="subtle" @click=${this._restoreDeletedTimeEntries}>
                                            <i class="arrows-rotate"></i>
                                            Arbeitszeiten wiederherstellen
                                        </button>
                                    </div>
                                    <div class="horizontally-margined">
                                        <button class="subtle" @click=${this._unlockRevenueEntries}>
                                            <i class="book-open"></i>
                                            Kassenbuch öffnen
                                        </button>

                                        <ptc-unlock-time-entries-form></ptc-unlock-time-entries-form>
                                    </div>
                                </div>
                            </div>
                            <div class="double-padded border-top">
                                <div class="margined uppercase">Unternehmensdaten</div>
                                <div>${this._renderCompanyForm(company)}</div>
                            </div>

                            <div class="double-padded border-top">
                                <div class="margined uppercase">
                                    <strong>${company.venues.length}</strong>
                                    Standorte
                                </div>

                                <div class="spacing grid">
                                    ${company.venues.map(
                                        (venue) => html`
                                            <div class="horizontal center-aligning layout double-padded box">
                                                <div class="stretch">${venue.name}</div>
                                                <button class="transparent icon" hidden>
                                                    <i class="archive"></i>
                                                </button>
                                                <button class="transparent icon" hidden>
                                                    <i class="trash"></i>
                                                </button>
                                            </div>
                                        `
                                    )}
                                </div>
                            </div>

                            <div class="double-padded border-top">
                                ${this._importResult &&
                                html`
                                    <div
                                        class="margined ${this._importResult.status === "success"
                                            ? "green"
                                            : "red"} padded box spacing start-aligning vertical layout"
                                    >
                                        ${this._importResult?.status === "failure"
                                            ? html`
                                                  <div class="horizontal layout center-aligning">
                                                      <div class="stretch bold">
                                                          Achtung! Folgende Fehler müssen behoben werden, damit die
                                                          Mitarbeiter importiert werden können:
                                                      </div>
                                                  </div>
                                              `
                                            : html`
                                                  <div class="horizontal layout center-aligning">
                                                      <div class="stretch bold">Import erfolgreich!</div>
                                                  </div>
                                              `}
                                        ${this._importResult.errors.length > 0
                                            ? this._importResult.errors.map((message) => {
                                                  return html`
                                                      <div class="horizontal layout center-aligning">
                                                          <i
                                                              class="big ${this._importResult?.status === "success"
                                                                  ? "badge-check"
                                                                  : "exclamation-triangle"} right-margined"
                                                          ></i>
                                                          <div class="stretch">${message}</div>
                                                      </div>
                                                  `;
                                              })
                                            : ""}
                                    </div>
                                `}
                                <div
                                    class="margined uppercase between-justifying end-aligning layout"
                                    style="margin-right: 0; margin-top: 0;"
                                >
                                    <div>
                                        <strong>${activeEmployees.length}</strong>
                                        Mitarbeiter
                                    </div>

                                    <button class="small slim ghost" @click=${this._import}>
                                        <i class="file-import"></i>
                                        Importieren
                                    </button>
                                </div>

                                <div class="spacing grid" style="--grid-column-width: 16em">
                                    ${employees.map(
                                        (e) => html`
                                            <div class="padded box spacing horizontal layout">
                                                <ptc-avatar .employee=${e} class="small"></ptc-avatar>
                                                <div class="stretch collapse">
                                                    <div class="horizontal center-aligning layout">
                                                        <div class="small stretch collapse ellipsis">${e.name}</div>
                                                        <a
                                                            href="tel:${e.phone}"
                                                            class="tiny slim transparent button"
                                                            ?disabled=${!e.phone}
                                                            ${popover(
                                                                html`
                                                                    <div>${e.phone}</div>
                                                                    ${e.phone2 ? html`<div>${e.phone}</div>` : ""}
                                                                `,
                                                                { class: "tooltip non-interactive", trigger: "hover" }
                                                            )}
                                                        >
                                                            <i class="phone"></i>
                                                        </a>
                                                        <a
                                                            href="https://secure.helpscout.net/mailbox/a4f33595e56f4aff/new-ticket/?email=${encodeURIComponent(
                                                                e.email
                                                            )}"
                                                            target="_blank"
                                                            class="tiny slim transparent button"
                                                            ?disabled=${!e.email}
                                                            ${popover(html` ${e.email} `, {
                                                                class: "tooltip non-interactive",
                                                                trigger: "hover",
                                                            })}
                                                        >
                                                            <i class="envelope"></i>
                                                        </a>
                                                        <ptc-popover trigger="hover" class="tooltip" non-interactive>
                                                        </ptc-popover>
                                                        <button
                                                            class="tiny slim transparent"
                                                            ${popover(
                                                                html`
                                                                    <div class="vertical layout">
                                                                        ${!e.isContactPerson
                                                                            ? html`
                                                                                  <button
                                                                                      class="transparent slim"
                                                                                      @click=${() =>
                                                                                          this._setContactPerson(
                                                                                              e,
                                                                                              true
                                                                                          )}
                                                                                  >
                                                                                      <i class="user-headset"></i>
                                                                                      Kontaktperson Hinzufügen
                                                                                  </button>
                                                                              `
                                                                            : html`
                                                                                  <button
                                                                                      class="transparent slim"
                                                                                      @click=${() =>
                                                                                          this._setContactPerson(
                                                                                              e,
                                                                                              false
                                                                                          )}
                                                                                  >
                                                                                      <i class="phone-slash"></i>
                                                                                      Kontaktperson Entfernen
                                                                                  </button>
                                                                              `}
                                                                    </div>
                                                                `,
                                                                { class: "tooltip" }
                                                            )}
                                                        >
                                                            <i class="ellipsis-h"></i>
                                                        </button>
                                                    </div>
                                                    <div class="tiny pills">
                                                        ${e.role === Role.Owner
                                                            ? html`<div class="purple pill">
                                                                  <i class="user-crown"></i> Besitzer
                                                              </div>`
                                                            : e.role === Role.Manager
                                                              ? html`<div class="orange pill">
                                                                    <i class="user-tie"></i> Manager
                                                                </div>`
                                                              : html`<div class="pill">
                                                                    <i class="user-hard-hat"></i> Mitarbeiter
                                                                </div>`}
                                                        ${e.isContactPerson
                                                            ? html`
                                                                  <div class="blue pill">
                                                                      <i class="user-headset"></i> Kontaktperson
                                                                  </div>
                                                              `
                                                            : ""}
                                                        ${e.status !== EmployeeStatus.Active
                                                            ? html`
                                                                  <div class="${employeeStatusColor(e.status)} pill">
                                                                      ${employeeStatusLabel(e.status)}
                                                                  </div>
                                                              `
                                                            : ""}
                                                    </div>
                                                </div>
                                            </div>
                                        `
                                    )}
                                    ${!this._displayAllEmployees
                                        ? html`
                                              <button class="subtle" @click=${() => (this._displayAllEmployees = true)}>
                                                  Alle Anzeigen
                                              </button>
                                          `
                                        : html`
                                              <button
                                                  class="subtle"
                                                  @click=${() => (this._displayAllEmployees = false)}
                                              >
                                                  Weniger Anzeigen
                                              </button>
                                          `}
                                </div>
                            </div>

                            ${this._renderBillingForm()}

                            <div class="double-padded border-top">
                                <div class="margined uppercase">Zahlungsmittel</div>

                                ${company.billing?.paymentMethods.map(
                                    (pm) => html`
                                        <div class="payment-method">
                                            <div class="payment-method-detail">
                                                <div class="payment-method-detail-label">Kontoinhaber:</div>
                                                <div class="payment-method-detail-value">
                                                    ${pm.billing_details.name}
                                                </div>
                                            </div>

                                            <div class="payment-method-detail">
                                                <div class="payment-method-detail-label">IBAN:</div>
                                                <div class="payment-method-detail-value">
                                                    DE •••• •••• •••• •••• ${pm.sepa_debit!.last4}
                                                </div>
                                            </div>

                                            <div class="payment-method-detail">
                                                <div class="payment-method-detail-label">Hinzugefügt:</div>
                                                <div class="payment-method-detail-value">
                                                    ${formatDate(new Date(pm.created * 1000))}
                                                </div>
                                            </div>

                                            <div
                                                class="pill"
                                                ?hidden=${company.billing!.customer!.invoice_settings!
                                                    .default_payment_method !== pm.id}
                                            >
                                                Standard
                                            </div>
                                        </div>
                                    `
                                )}

                                <button @click=${() => this._addPaymentMethod(company)} class="subtle">
                                    <i class="credit-card"></i>
                                    Zahlungsmittel Hinzufügen
                                </button>

                                <button
                                    ?hidden=${!company.billing}
                                    @click=${() => this._openStripeDashboard(company)}
                                    class="subtle"
                                >
                                    <i class="arrow-right"></i>
                                    In Stripe Öffnen
                                </button>
                            </div>

                            <div class="double-padded border-top">
                                <div class="margined uppercase">Danger Zone</div>

                                <div>
                                    <button class="subtle red" @click=${this._deleteCompany}>
                                        <i class="trash"></i>
                                        Unternehmen Löschen
                                    </button>
                                </div>
                            </div>
                        </div>
                    </ptc-scroller>
                </div>
                <div class="padded vertical layout border-left" style="width: 25em">
                    <form class="new-event-form" @submit=${this._submitNewEvent}>
                        <div class="relative">
                            <ptc-textarea
                                name="comment"
                                @input=${() => this.requestUpdate()}
                                disableEmojis
                                placeholder="Neuer Kommentar..."
                            ></ptc-textarea>
                            <div
                                class="smaller padded absolute bottom horizontal center-aligning spacing layout fill-horizontally"
                            >
                                <select class="skinny" name="status" @change=${() => this.requestUpdate()}>
                                    <option .value=${""}>[Neuer Status]</option>
                                    <option .value=${CompanyStatus.Trialing}>
                                        ${companyStatusLabel(CompanyStatus.Trialing)}
                                    </option>
                                    <option .value=${CompanyStatus.Probation}>
                                        ${companyStatusLabel(CompanyStatus.Probation)}
                                    </option>
                                    <option .value=${CompanyStatus.Active}>
                                        ${companyStatusLabel(CompanyStatus.Active)}
                                    </option>
                                    <option .value=${CompanyStatus.Paused}>
                                        ${companyStatusLabel(CompanyStatus.Paused)}
                                    </option>
                                    <option .value=${CompanyStatus.Canceled}>
                                        ${companyStatusLabel(CompanyStatus.Canceled)}
                                    </option>
                                    <option .value=${CompanyStatus.PaymentOverdue}>
                                        ${companyStatusLabel(CompanyStatus.PaymentOverdue)}
                                    </option>
                                </select>
                                <div class="horizontal center-aligning layout" ?hidden=${!this._newEventData.status}>
                                    <label>Ab:</label>
                                    <ptc-date-input
                                        name="date"
                                        datePicker="popover"
                                        style="--date-input-padding: 0.3em"
                                    ></ptc-date-input>
                                </div>
                                <div class="stretch"></div>
                                <button
                                    class="slim primary"
                                    ?disabled=${!this._newEventData.comment && !this._newEventData.status}
                                >
                                    Absenden
                                </button>
                            </div>
                        </div>
                    </form>

                    <ptc-scroller class="stretch collapse" id="timeLineScroller">
                        ${this._renderLifeCycleEvents()}
                        <div style="height: 30%;"></div>
                    </ptc-scroller>
                </div>
            </div>

            <div class="fullbleed centering layout scrim" ?hidden=${!this._loading}>
                <ptc-spinner .active=${this._loading}></ptc-spinner>
            </div>
        `;
    }
}
